var prodcat = prodcat || {};

prodcat.ui = prodcat.ui || {};
prodcat.data = prodcat.data || {};
var site = site || {};

site.template = site.template || {};

(function ($) {
  var lastBP;

  $(document).on('product:quickshop', function (event, args) {
    Drupal.behaviors.quickshop.launch(args);
    $(document).trigger('email_signup:js-pony_popup');
  });
  $(document).on('product.skuSelect, product.skuDisplay', '.js-product', function (e, skuBaseId) {
    var skuData = prodcat.data.getSku(skuBaseId);
    var hasDiscount = !!skuData.PRICE2;

    Drupal.behaviors.quickshop.togglePrice(hasDiscount);
  });
  // Modernizr.touch = true;
  Drupal.behaviors.quickshop = {
    $grids: $(),
    $openedQS: $(),
    attached: false,

    togglePrice: function (hasDiscount) {
      var self = this;

      if (self.$openedQS.length < 1) {
        // can't use context, the QS overlay is added with cBox. it's out of any context used here
        self.$openedQS = $('.js-quickshop');
      }
      if (hasDiscount) {
        self.$openedQS.find('.price').addClass('discount_price');
        self.$openedQS.find('.old_price').removeClass('hidden');
      } else {
        self.$openedQS.find('.price').removeClass('discount_price');
        self.$openedQS.find('.old_price').addClass('hidden');
      }
    },

    initGrid: function ($grids, size) {
      var isMobile = typeof size !== undefined && size === 'small';
      var self = this;

      // Insert quickshop placeholder divs into product grids.
      // On mobile there is a quickshop placeholder after each product thumb.
      // On desktop there's a shared one after each row of product thumbs.

      var isOverlay = $grids.hasClass('product-grid--quickshop-overlay');

      if (!isOverlay || isMobile) {
        $grids.has('.product-grid__item').each(function () {
          // First remove any existing quickshop wrappers in the grid.
          self.reset($(this));

          var i = 0;
          var classes = '';
          var rows = [];
          var isCarousel = $(this).hasClass('product-grid--carousel');
          var $row = $();
          var $container = $('<div class="js-quickshop-container quickshop-wrapper" />');
          var $items = $('.product-grid__item:visible', this);
          var iLen = $items.length;

          $items.each(function (index) {
            classes += ' js-quickshop-container--' + $(this).data('product-id');

            // For carousels, we just need all the classes
            if (isCarousel) {
              return;
            }

            // For mobile simply add the quickshop wrapper immediately following
            // the item:
            else if (isMobile) {
              $(this).after($container.clone()).next().addClass(classes);
              classes = '';
              rows.push($(this));
            }

            // For desktop add a new wrapper after every row of 3 items:
            else {
              var span = 1;

              if ($(this).data('colspan')) {
                span = $(this).data('colspan');
              }
              i += span;
              $row = $row.add(this);
              if (i === 3 || index + 1 === iLen) {
                if (i === 3) {
                  $(this).addClass('last'); // css targets this rather than nth-of-type
                }
                $(this).after($container.clone()).next().addClass(classes);
                rows.push($row);
                i = 0;
                $row = $();
                classes = '';
              }
            }
          });

          if (isCarousel) {
            // slick wraps our $items in its own stuff so we have to treat those
            // as items if they've been set.
            var $slides = $('.slick-slide', this);

            rows = $slides.length ? [$slides] : [$items];
            $(this).after($container.clone()).next().addClass(classes);
          }

          $(this).data('product-grid-rows', rows);
          self.$grids.add($(this));
        });
      }
    },

    reset: function ($grid) {
      var $container = $grid.hasClass('product-grid--carousel') ? $grid.parent() : $grid;

      $('.js-quickshop-container', $container).remove();
      $('.product-thumb', $grid).removeClass('active');
    },

    launch: function (args) {
      var self = this;

      // we reset the active QS on each launch
      self.$openedQS = $();
      var isCarousel = !!args.$ele.closest('.slick-initialized').length;
      // The container is in a slightly different place in the DOM if the grid is a carousel
      var $containers;

      if (isCarousel) {
        $containers = args.$ele.closest('.product-grid--carousel').siblings('.js-quickshop-container');
      } else {
        $containers = args.$ele.closest('.product-grid__content').find('.js-quickshop-container');
      }
      if (!$containers.length) {
        $containers = args.$ele.closest('.js-quickshop-container-parent').find('.js-quickshop-container');
      }

      var $container = $containers.filter('.js-quickshop-container--' + args.product.PRODUCT_ID);
      var $active = $containers.filter('.active');
      var gridItemSelector = isCarousel ? '.slick-slide' : '.product-grid__item';
      var isOverlay = args.$ele.closest('.product-grid--quickshop-overlay');
      var $gridItem = args.$ele.closest(gridItemSelector);
      var $thumb = $('.product-thumb', $gridItem);
      var $thumbs = $('.product-thumb', $gridItem.parent());
      var alreadyActive = $thumb.hasClass('active');
      var defaultSpeed = 400;
      var speed = typeof args.speed !== 'undefined' ? args.speed : defaultSpeed;
      var qt = 0;
      var qo = -200;
      var isMobile = Unison.fetch.now().name === 'small';

      function _close() {
        $thumb.removeClass('active');
        $container.slideUp(defaultSpeed, function () {
          $(this).removeClass('active active--1 active--2 active--3').empty();
        });
      }

      // If already active, close and return.
      if (alreadyActive) {
        _close();

        return;
      }

      // We're potentially about to remove some skus, so avoid doing it by
      // reference.
      var product = jQuery.extend(true, {}, args.product);
      // If the product has data-available-skus set that means to only display the
      // skus available in that attribute.
      var availableSkusString = args.$ele.data('available-skus');

      if (availableSkusString) {
        var skus = [];

        _.each(_.map(availableSkusString.split(','), $.trim), function (skuID) {
          _.each(product.skus, function (sku) {
            if (sku.SKU_ID === skuID) {
              skus.push(sku);
            }
          });
        });
        if (skus.length) {
          product.skus = skus;
        }
      }

      // Render quickshop
      var rendered = site.template.get({
        name: 'quickshop',
        data: $.extend({ Drupal: Drupal }, product)
      });

      // fire QV tagging event
      if (typeof window.CATEGORY_ID != 'undefined' && typeof args.product.PRODUCT_ID != 'undefined') {
        QV_prod_id = window.CATEGORY_ID + '~' + args.product.PRODUCT_ID;
        $(window).trigger('MPP:productQV', [QV_prod_id]);
      }

      if (isOverlay[0]) {
        generic.overlay.launch({
          content: rendered,
          cssClass: 'colorbox__quickshop',
          cssStyle: {
            height: '415px',
            width: '746px',
            opacity: '0.68'
          }
        });

        var $qsColorBox = $('.colorbox__quickshop');

        $('.js-sku-menu', $qsColorBox).trigger('change');
        prodcat.ui.init($qsColorBox);
      } else {
        // Populate and show the container we're activating
        $active.hide();
        $container.html(rendered).show();

        prodcat.ui.init($container);

        // Select the active sku
        // @TODO: this should be part of the init stuff, and product.init should
        // be an event.
        // $('.js-sku-menu').trigger('change');
        $('.js-product', $container).trigger('product.init');

        $('.js-quickshop-close', $container)
          .off('click.quickShop')
          .on('click.quickShop', function (e) {
            e.preventDefault();
            _close();
            // Show QS link
            $('.hideqs').removeClass('hideqs');
          });

        qt = $container.offset().top;

        // Scroll animation:
        $('html, body').animate(
          {
            scrollTop: qt + qo
          },
          {
            duration: speed
          }
        );

        $thumbs.removeClass('active');
        $thumb.addClass('active');

        // Determine which item is active in the row so css can position the arrow.
        // The arrow is inside of the quickshop rather than relative to the thumb
        // so that it is part of the expand/collapse animation.

        var i;

        if (isCarousel) {
          i = ($gridItem.index() + 1) % 3 || 3;
        } else {
          i = $gridItem.prevUntil('.js-quickshop-container').length + 1;
        }

        // Quickshop animation:
        // 1. If current quickshop is already active, no need to animate anything
        if ($container.hasClass('active')) {
          $container.removeClass('active--1 active--2 active--3').addClass('active--' + i);

          return;
        }

        // Temporarily set the heights of the quickshop images based on the
        // expected image ratios and the current widths.
        var $largeImgs = $('.product-gallery__photo--large', $container);
        var $thumbImgs = $('.product-gallery__photo--thumb', $container);
        var largeImgBaseWidth = $largeImgs.first().data('width') - 0;
        var thumbImgBaseWidth = $thumbImgs.first().data('width') - 0;
        var largeImgBaseHeight = $largeImgs.first().data('height') - 0;
        var thumbImgBaseHeight = $thumbImgs.first().data('height') - 0;
        var largeImgHeightRatio = largeImgBaseHeight / largeImgBaseWidth;
        var thumbImgHeightRatio = thumbImgBaseHeight / thumbImgBaseWidth;
        var largeImgHeight = $largeImgs.first().width() * largeImgHeightRatio;
        var thumbImgHeight = $thumbImgs.first().width() * thumbImgHeightRatio;

        if (largeImgHeight) {
          $largeImgs.height(largeImgHeight);
        }
        if (thumbImgHeight) {
          $thumbImgs.height(thumbImgHeight);
        }

        // 2. If there are other active quickshops, animate them closed
        $active.show().slideUp(speed, function () {
          $(this).removeClass('active active--1 active--2 active--3').empty();
        });

        // 3. Hide quickshop and animate it open
        $container
          .addClass('active active--' + i)
          .hide()
          .slideDown(speed, function () {
            $largeImgs.add($thumbImgs).css('height', '');
          });
      }
      var $addBtn = $('.js-add-to-cart', $('.js-product__details'));

      if ($addBtn.length > 0) {
        // Adding data test attribute for quickshop add to bag
        if (
          typeof Drupal.settings.globals_variables.show_test_attribute != 'undefined' &&
          Drupal.settings.globals_variables.show_test_attribute
        ) {
          $addBtn.attr('data-test-id', 'add-to-cart');
        }
        prodcat.ui.addCartButton.updateInvStatus($addBtn);
        var $statusList = $('.js-inv-status-list', $('.js-product__details'));
        var skuBaseId = $statusList.data('sku-base-id');
        var skuData = prodcat.data.getSku(skuBaseId);

        Drupal.behaviors.inventoryStatusV1.update($statusList, skuData);
      }
    },

    attach: function (context) {
      if (this.attached) {
        return;
      }
      this.attached = true;
      Unison.on('change', function (bp) {
        // Do nothing when toggling between large and medium.
        if ((lastBP === 'large' && bp.name === 'medium') || (lastBP === 'medium' && bp.name === 'large')) {
          return;
        }
        lastBP = bp.name;

        Drupal.behaviors.quickshop.initGrid($('.product-grid--quickshop'), bp.name);
      });

      // These use Unison, and jQuery fires its ready event before Unison, so
      // use an interval to wait till it's ready.
      var size = Unison.fetch.now() ? Unison.fetch.now().name : 'medium';
      var intvl;

      Drupal.behaviors.quickshop.initGrid($('.product-grid--quickshop', context), size);
      if (!Unison.fetch.now()) {
        intvl = setInterval(function () {
          if (Unison.fetch.now()) {
            size = Unison.fetch.now().name;
            Drupal.behaviors.quickshop.initGrid($('.product-grid--quickshop', context), size);
            clearInterval(intvl);
          }
        }, 50);
      }
    }
  };
})(jQuery);
